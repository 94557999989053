<template>
    <layout-base id="about">
        <v-row align="center">
            <v-col>
                <h1 class="display-2">¿Quién Soy?</h1>
                <br>
                <p>
                    Titulado de Ingeniería en Ejecución en informática Mención Desarrollo de Sistemas con experiencia
                    en el área de desarrollo de aplicaciones. Me especializo principalmente en asp net core c# y vuejs,
                    pero tengo conocimientos también en otros entornos de desarrollo como Firebase, Android,
                    Python y otros.
                </p>
                <p>
                    Me encuentro constantemente en busca de nuevos desafíos y proyectos que me permitan tener un crecimiento
                    profesional y personal.
                </p>
                <p>
                    También tengo mucho interés en el mundo IOT y electrónica, me gusta pensar en como mejorar la
                    calidad de vida de las personas desarrollando e implementando proyectos de este tipo,
                    desafortunadamente, no me he encontrado con proyectos de esta categoría aún.
                </p>
            </v-col>
        </v-row>
    </layout-base>
</template>
<script>
export default {
        name: 'AboutComponent'
    }
</script>
