<template>
    <layout-base id="portfolio">
        <h1>Portafolio</h1>

        <v-row align="center" >
            <v-col cols="4"  align="center">
                <a href="https://scrum.felipecaroca.com/"
                target="_blank"
                >
                Scrum poker               
                </a>
            </v-col>
            
            <v-col cols="4" align="center">
                Pronto más demos...
            </v-col>
        </v-row>
    </layout-base>
</template>

<script>

    export default {
        name: 'PortfolioComponent',
    }
</script>
