<template>
    <layout-base id="presentation">
        <v-row align="end" justify="end" style="padding-bottom: 30px;">
            <v-col>
                <h1 class="display-2" align="right">FELIPE CAROCA OSORIO</h1>
                <h1 class="display-3" align="right">INGENIERO EN DESARROLLO DE SISTEMAS</h1>
            </v-col>
        </v-row>

    </layout-base>
</template>

<script>
    export default {
        name: 'PresentationComponent'
    }
</script>
