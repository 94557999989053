<template>
    <layout-base id="customers">
        <h1>Clientes</h1>
    </layout-base>
</template>

<script>
    export default {
        name: 'CustomersComponent'
    }
</script>
